<!-- src/components/Home.vue -->
<template>
  <div class="home-container">
    <h1>欢迎来到消磨时光的小游戏网站</h1>
    <div class="games-grid">
      <div class="game-card" v-for="game in games" :key="game.name">
        <div class="game-image">
          <img :src="game.image" :alt="game.name" />
        </div>
        <div class="game-info">
          <h2>{{ game.name }}</h2>
          <router-link :to="game.route">
            <button class="play-button">开始游戏</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      games: [
        {
          name: '2048',
          route: '/2048',
          image: require('@/assets/imgs/Game2048/game2048.png')
        },
        {
          name: '消消乐',
          route: '/xiaoxiaole',
          image: require('@/assets/imgs/XiaoXiaoLe/XiaoXiaoLe.png')
        },

        {
          name: '敲木鱼',
          route: '/qiaomuyu',
          image: require('@/assets/imgs/QiaoMuYu/qiaomu.png')
        },

        // {
        //   name: '俄罗斯方块',
        //   route: '/tetris',
        //   image: require('@/assets/imgs/XiaoXiaoLe/XiaoXiaoLe.png')
        // },
        // {
        //   name: '飞行小鸟',
        //   route: '/flappybird',
        //   image: require('@/assets/imgs/XiaoXiaoLe/XiaoXiaoLe.png')
        // },
        // {
        //   name: '飞行棋',
        //   route: '/feixingqi',
        //   // image: require('@/assets/images/feixingqi.png') // 替换为实际路径
        // },
        // {
        //   name: '扫雷',
        //   route: '/saolei',
        //   // image: require('@/assets/images/saolei.png') // 替换为实际路径
        // }
      ]
    };
  }
};
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  min-height: 100vh;
  background-color: #f0f2f5;
}

h1 {
  margin-bottom: 40px;
  color: #333333;
  font-size: 2.5rem;
  text-align: center;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
}

.game-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.game-image {
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}

.game-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.game-info {
  padding: 20px;
  text-align: center;
}

.game-info h2 {
  margin-bottom: 15px;
  color: #555555;
  font-size: 1.5rem;
}

.play-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.play-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* 响应式调整 */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .game-info h2 {
    font-size: 1.3rem;
  }

  .play-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
</style>
