<!-- src/components/GameXiaoXiaoLe.vue -->
<template>
  <div class="game-container">
    <h2>消消乐游戏</h2>
    <div class="game-info">
      <p>得分: {{ score }}</p>
      <p>剩余回合: {{ remainingRounds }}</p>
      <button @click="resetGame">重新开始</button>

        <button class="back-button" @click="backToHome()">返回主页</button>

    </div>
    <div class="game-board">
      <div
          v-for="(cell, index) in grid"
          :key="index"
          :class="[
          'cell',
          cell.color,
          {
            selected: isSelected(index),
            eliminated: cell.eliminated,
            swapping: cell.swapping // 添加 swapping 类
          }
        ]"
          @click="handleCellClick(index)"
          @mousedown="startDrag($event, index)"
          @touchstart="startDrag($event, index)"
          :data-index="index"
      >
        <!-- 可在此添加方块内容，如图标等 -->
      </div>
    </div>
    <div v-if="gameOver" class="game-over">
      <p>游戏结束！您的得分: {{ score }}</p>
      <button @click="resetGame">再玩一次</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameXiaoXiaoLe',
  data() {
    return {
      gridSize: 8, // 8x8 网格
      grid: [],
      colors: ['red', 'blue', 'green', 'yellow', 'purple'],
      selectedCell: null,
      score: 0,
      gameOver: false,
      // 拖拽相关
      dragging: false,
      dragStartIndex: null,
      dragOverIndex: null,
      // 回合数
      totalRounds: 40,
      remainingRounds: 40,
      // 音效
      sounds: {
        swap: new Audio(require('@/assets/sounds/XiaoXiaoLe/swap.mp3')),
        background: new Audio(require('@/assets/sounds/XiaoXiaoLe/background.mp3')),
        gameOver: new Audio(require('@/assets/sounds/XiaoXiaoLe/game-over.mp3')),
        eliminate: new Audio(require('@/assets/sounds/XiaoXiaoLe/eliminate.mp3')),
      },
    };
  },
  mounted() {
    // 预加载音效
    Object.values(this.sounds).forEach(sound => {
      sound.load();
    });
    this.resetGame();
    // 播放背景音乐
    this.sounds.background.loop = true;
    this.sounds.background.volume = 0.5; // 设置背景音乐音量
    this.sounds.background.play();
    // 监听浏览器的退格键事件
    window.addEventListener('popstate', this.stopBackgroundMusic);

  },
  beforeDestroy() {
    // 停止背景音乐
    this.sounds.background.pause();
    this.sounds.background.currentTime = 0;
    // 移除事件监听
    window.removeEventListener('popstate', this.stopBackgroundMusic);

  },
  methods: {
    // 初始化或重置游戏
    resetGame() {
      this.score = 0;
      this.gameOver = false;
      this.remainingRounds = this.totalRounds;
      this.grid = this.generateInitialGrid();
      this.checkAllMatches();
      // 重启背景音乐
      this.sounds.background.currentTime = 0;
      this.sounds.background.play();
    },
    // 生成初始网格，确保没有初始匹配
    generateInitialGrid() {
      let grid = [];
      for (let i = 0; i < this.gridSize * this.gridSize; i++) {
        let color;
        do {
          color = this.getRandomColor();
          // 防止生成三个以上相同颜色的方块
        } while (
            (i >= 2 &&
                grid[i - 1].color === color &&
                grid[i - 2].color === color) ||
            (i >= this.gridSize * 2 &&
                grid[i - this.gridSize].color === color &&
                grid[i - 2 * this.gridSize].color === color)
            );
        grid.push({ color, eliminated: false, swapping: false });
      }
      return grid;
    },
    // 获取随机颜色
    getRandomColor() {
      const index = Math.floor(Math.random() * this.colors.length);
      return this.colors[index];
    },
    // 处理方块点击事件
    handleCellClick(index) {
      if (this.gameOver) return;
      if (this.selectedCell === null) {
        this.selectedCell = index;
      } else {
        // 检查是否为相邻方块
        if (this.isAdjacent(this.selectedCell, index)) {
          // 记录当前选择的方块
          const firstIndex = this.selectedCell;
          const secondIndex = index;
          // 交换方块并播放交换音效
          this.swapCells(firstIndex, secondIndex);
          this.sounds.swap.currentTime = 0;
          this.sounds.swap.play();
          // 减少回合数
          this.decrementRound();
          this.selectedCell = null;
          // 检查是否有匹配
          if (!this.hasMatch()) {
            // 如果没有匹配，交换回去
            setTimeout(() => {
              // 检查索引有效性
              if (
                  this.isValidIndex(firstIndex) &&
                  this.isValidIndex(secondIndex)
              ) {
                this.swapCells(secondIndex, firstIndex);
                // 可选择在这里播放交换回去的音效
              }
            }, 300); // 等待动画完成
          } else {
            // 如果有匹配，处理消除并播放消除音效
            this.processMatches();
            this.sounds.eliminate.currentTime = 0;
            this.sounds.eliminate.play();
          }
        } else {
          // 重新选择
          this.selectedCell = index;
        }
      }
    },
    // 拖拽开始
    startDrag(event, index) {
      event.preventDefault();
      this.dragging = true;
      this.dragStartIndex = index;
      // 添加全局事件监听
      window.addEventListener('mousemove', this.onDragMove);
      window.addEventListener('mouseup', this.endDrag);
      window.addEventListener('touchmove', this.onDragMove);
      window.addEventListener('touchend', this.endDrag);
    },
    // 拖拽移动
    onDragMove(event) {
      if (!this.dragging) return;
      let clientX, clientY;
      if (event.type.startsWith('touch')) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }
      const element = document.elementFromPoint(clientX, clientY);
      if (element && element.classList.contains('cell')) {
        const index = parseInt(element.getAttribute('data-index'));
        if (
            index !== this.dragStartIndex &&
            this.isAdjacent(this.dragStartIndex, index)
        ) {
          this.dragOverIndex = index;
        }
      }
    },
    // 拖拽结束
    endDrag() {
      if (this.dragging && this.dragOverIndex !== null) {
        const firstIndex = this.dragStartIndex;
        const secondIndex = this.dragOverIndex;
        // 交换方块并播放交换音效
        this.swapCells(firstIndex, secondIndex);
        this.sounds.swap.currentTime = 0;
        this.sounds.swap.play();
        // 减少回合数
        this.decrementRound();
        if (!this.hasMatch()) {
          // 如果没有匹配，交换回去
          setTimeout(() => {
            // 检查索引有效性
            if (
                this.isValidIndex(firstIndex) &&
                this.isValidIndex(secondIndex)
            ) {
              this.swapCells(secondIndex, firstIndex);
            }
          }, 300); // 等待动画完成
        } else {
          // 如果有匹配，处理消除并播放消除音效
          this.processMatches();
          this.sounds.eliminate.currentTime = 0;
          this.sounds.eliminate.play();
        }
      }
      this.dragging = false;
      this.dragStartIndex = null;
      this.dragOverIndex = null;
      // 移除全局事件监听
      window.removeEventListener('mousemove', this.onDragMove);
      window.removeEventListener('mouseup', this.endDrag);
      window.removeEventListener('touchmove', this.onDragMove);
      window.removeEventListener('touchend', this.endDrag);
    },
    // 判断两个方块是否相邻
    isAdjacent(index1, index2) {
      const row1 = Math.floor(index1 / this.gridSize);
      const col1 = index1 % this.gridSize;
      const row2 = Math.floor(index2 / this.gridSize);
      const col2 = index2 % this.gridSize;
      return (
          (Math.abs(row1 - row2) === 1 && col1 === col2) ||
          (Math.abs(col1 - col2) === 1 && row1 === row2)
      );
    },
    // 交换两个方块并添加动画
    swapCells(index1, index2) {
      if (!this.isValidIndex(index1) || !this.isValidIndex(index2)) {
        console.error('Invalid indices for swapping:', index1, index2);
        return;
      }
      // 添加交换动画类
      this.grid[index1].swapping = true;
      this.grid[index2].swapping = true;

      // 触发 Vue 的 reactivity
      this.$forceUpdate();

      // 交换颜色
      const temp = this.grid[index1].color;
      this.grid[index1].color = this.grid[index2].color;
      this.grid[index2].color = temp;

      // 移除交换动画类
      setTimeout(() => {
        this.grid[index1].swapping = false;
        this.grid[index2].swapping = false;
      }, 300); // 动画持续时间应与CSS动画一致
    },
    // 检查索引是否有效
    isValidIndex(index) {
      return index >= 0 && index < this.grid.length;
    },
    // 减少回合数
    decrementRound() {
      if (this.remainingRounds > 0) {
        this.remainingRounds--;
        if (this.remainingRounds === 0) {
          this.endGame();
        }
      }
    },
    // 结束游戏并播放游戏结束音效
    endGame() {
      this.gameOver = true;
      this.sounds.background.pause();
      this.sounds.gameOver.play();
    },
    // 检查当前网格是否有匹配
    hasMatch() {
      return this.findMatches().length > 0;
    },
    // 查找所有匹配
    findMatches() {
      let matches = [];
      // 检查行
      for (let row = 0; row < this.gridSize; row++) {
        let matchCount = 1;
        for (let col = 1; col < this.gridSize; col++) {
          const current = row * this.gridSize + col;
          const prev = row * this.gridSize + col - 1;
          if (this.grid[current].color === this.grid[prev].color) {
            matchCount++;
          } else {
            if (matchCount >= 3) {
              for (let i = 0; i < matchCount; i++) {
                matches.push(row * this.gridSize + (col - 1 - i));
              }
            }
            matchCount = 1;
          }
        }
        if (matchCount >= 3) {
          for (let i = 0; i < matchCount; i++) {
            matches.push(row * this.gridSize + (this.gridSize - 1 - i));
          }
        }
      }
      // 检查列
      for (let col = 0; col < this.gridSize; col++) {
        let matchCount = 1;
        for (let row = 1; row < this.gridSize; row++) {
          const current = row * this.gridSize + col;
          const prev = (row - 1) * this.gridSize + col;
          if (this.grid[current].color === this.grid[prev].color) {
            matchCount++;
          } else {
            if (matchCount >= 3) {
              for (let i = 0; i < matchCount; i++) {
                matches.push((row - 1 - i) * this.gridSize + col);
              }
            }
            matchCount = 1;
          }
        }
        if (matchCount >= 3) {
          for (let i = 0; i < matchCount; i++) {
            matches.push((this.gridSize - 1 - i) * this.gridSize + col);
          }
        }
      }
      // 去重
      return [...new Set(matches)];
    },
    // 处理所有匹配
    processMatches() {
      let matches = this.findMatches();
      if (matches.length === 0) return;
      // Determine if multiple eliminations
      const multipleEliminations = matches.length > 3;
      // Mark matched tiles for elimination
      matches.forEach(index => {
        if (this.isValidIndex(index)) {
          this.grid[index].eliminated = true;
          if (multipleEliminations) {
            this.grid[index].multipleEliminated = true;
          }
        }
      });
      // Update score
      this.updateScore(matches.length);
      // Trigger elimination animation and then remove tiles
      setTimeout(() => {
        matches.forEach(index => {
          if (this.isValidIndex(index)) {
            this.grid[index].color = null;
            this.grid[index].eliminated = false;
            if (multipleEliminations) {
              this.grid[index].multipleEliminated = false;
            }
          }
        });
        // Drop tiles
        this.dropTiles();
        // Fill empty tiles
        this.fillEmptyTiles();
        // Recursively check for new matches
        this.$nextTick(() => {
          const newMatches = this.findMatches();
          if (newMatches.length > 0) {
            this.processMatches();
            // Play elimination sound
            this.sounds.eliminate.currentTime = 0;
            this.sounds.eliminate.play();
          } else {
            // Check if any moves are possible
            if (!this.canMakeAnyMove()) {
              this.endGame();
            }
          }
        });
      }, 600); // Duration of elimination animation
    },
    updateScore(matchCount) {
      this.score += matchCount * 10;
    },
    // 让方块下落
    dropTiles() {
      for (let col = 0; col < this.gridSize; col++) {
        for (let row = this.gridSize - 1; row >= 0; row--) {
          const index = row * this.gridSize + col;
          if (this.grid[index].color === null) {
            // 查找上方第一个有颜色的方块
            for (let k = row - 1; k >= 0; k--) {
              const upperIndex = k * this.gridSize + col;
              if (this.grid[upperIndex].color !== null) {
                this.grid[index].color = this.grid[upperIndex].color;
                this.grid[upperIndex].color = null;
                break;
              }
            }
          }
        }
      }
    },
    // 填充空白方块
    fillEmptyTiles() {
      for (let i = 0; i < this.grid.length; i++) {
        if (this.grid[i].color === null) {
          this.grid[i].color = this.getRandomColor();
        }
      }
    },
    // 检查是否有可移动的方块
    canMakeAnyMove() {
      for (let i = 0; i < this.grid.length; i++) {
        // 检查右边和下边的方块
        const right = i % this.gridSize < this.gridSize - 1 ? i + 1 : null;
        const down = i + this.gridSize < this.grid.length ? i + this.gridSize : null;
        if (right !== null) {
          // 交换并检查是否有匹配
          this.swapCells(i, right);
          if (this.hasMatch()) {
            this.swapCells(i, right);
            return true;
          }
          this.swapCells(i, right);
        }
        if (down !== null) {
          this.swapCells(i, down);
          if (this.hasMatch()) {
            this.swapCells(i, down);
            return true;
          }
          this.swapCells(i, down);
        }
      }
      return false;
    },
    // 检查并移除所有初始的匹配（防止游戏开始时有匹配）
    checkAllMatches() {
      let matches = this.findMatches();
      while (matches.length > 0) {
        matches.forEach(index => {
          if (this.isValidIndex(index)) {
            this.grid[index].color = this.getRandomColor();
          }
        });
        matches = this.findMatches();
      }
    },
    stopBackgroundMusic() {
      this.sounds.background.pause();
      this.sounds.background.currentTime = 0;
    },
    backToHome() {
      this.stopBackgroundMusic();
      this.$router.push('/');
    },
    // 判断方块是否被选中
    isSelected(index) {
      return this.selectedCell === index;
    },
  },
};
</script>

<style scoped>
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 800px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  background-color: #fafafa;
  font-family: 'Arial', sans-serif;
  padding: 20px; /* 使用固定单位的内边距 */
  box-sizing: border-box;
}

h2 {
  margin-bottom: 20px; /* 使用固定单位的外边距 */
  color: #555555;
  font-size: 2em;
  text-align: center;
}

.game-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* 使用固定单位的外边距 */
}

.game-info p {
  margin: 10px 20px; /* 使用固定单位的外边距 */
  font-size: 1.2em;
  color: #333333;
}

.game-info button {
  padding: 10px 20px; /* 使用固定单位的内边距 */
  font-size: 1em;
  border: none;
  border-radius: 6px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 10px 10px; /* 使用固定单位的外边距 */
}

.game-info button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.back-button {
  padding: 10px 20px; /* 使用固定单位的内边距 */
  font-size: 1em;
  border: none;
  border-radius: 6px;
  background-color: #2196F3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 10px 10px; /* 使用固定单位的外边距 */
}

.back-button:hover {
  background-color: #0b7dda;
  transform: scale(1.05);
}

.game-board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  gap: 10px; /* 使用固定单位的网格间隔 */
  width: 600px; /* 设置固定宽度 */
  height: 600px; /* 设置固定高度 */
  max-width: 600px;
  max-height: 600px;
  position: relative;
}

.cell {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s, box-shadow 0.3s;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell:hover {
  transform: scale(1.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.cell.selected {
  outline: 2px solid #ff9800; /* 使用固定单位的轮廓 */
  animation: pulse 0.6s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px #ff9800;
  }
  50% {
    box-shadow: 0 0 20px #ff9800;
  }
  100% {
    box-shadow: 0 0 10px #ff9800;
  }
}

.cell.eliminated {
  animation: explode 0.8s forwards;
}

@keyframes explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2) rotate(90deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(180deg);
    opacity: 0;
  }
}

@keyframes multipleExplode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.5) rotate(45deg);
    opacity: 0.75;
  }
  50% {
    transform: scale(2) rotate(90deg);
    opacity: 0.5;
  }
  75% {
    transform: scale(2.5) rotate(135deg);
    opacity: 0.25;
  }
  100% {
    transform: scale(3) rotate(180deg);
    opacity: 0;
  }
}

.cell.multiple-eliminated {
  animation: multipleExplode 1s forwards;
}
.cell.swapping {
  animation: swapAnimation 0.3s;
}

@keyframes swapAnimation {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* 方块颜色 */
.red {
  background-color: #f28b82;
  transition: background-color 0.3s;
}

.blue {
  background-color: #aecbfa;
  transition: background-color 0.3s;
}

.green {
  background-color: #ccff90;
  transition: background-color 0.3s;
}

.yellow {
  background-color: #fff475;
  transition: background-color 0.3s;
}

.purple {
  background-color: #d7aefb;
  transition: background-color 0.3s;
}

.game-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  animation: fadeIn 0.5s forwards;
  padding: 20px; /* 使用固定单位的内边距 */
  box-sizing: border-box;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.game-over p {
  font-size: 2.5em;
  margin-bottom: 30px; /* 使用固定单位的外边距 */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
}

.game-over button {
  padding: 15px 30px; /* 使用固定单位的内边距 */
  font-size: 1.5em;
  border: none;
  border-radius: 8px;
  background-color: #ff5722;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.game-over button:hover {
  background-color: #e64a19;
  transform: scale(1.05);
}

/* 响应式字体调整 */
@media (max-width: 600px) {
  .game-container {
    padding: 10px; /* 调整内边距 */
  }

  h2 {
    font-size: 1.8em;
    margin-bottom: 15px; /* 调整外边距 */
  }

  .game-info p {
    font-size: 1em;
    margin: 8px 15px; /* 调整外边距 */
  }

  .game-info button, .back-button {
    font-size: 0.9em;
    padding: 8px 16px; /* 调整内边距 */
    margin: 8px 8px; /* 调整外边距 */
  }

  .game-board {
    width: 400px; /* 调整宽度 */
    height: 400px; /* 调整高度 */
    gap: 8px; /* 调整网格间隔 */
  }

  .game-over p {
    font-size: 2em;
    margin-bottom: 20px; /* 调整外边距 */
  }

  .game-over button {
    font-size: 1.2em;
    padding: 12px 25px; /* 调整内边距 */
  }
}
</style>

