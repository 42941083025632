<!-- src/components/Game2048.vue -->

<template>
  <div class="game-container">
    <div class="game-area">
      <h2>2048 游戏</h2>
      <div
          class="game-board"
          @keydown="handleKey"
          @keyup="handleKeyUp"
      tabindex="0"
      ref="board"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @mouseleave="handleMouseLeave"
      >
      <div
          v-for="(cell, index) in flatGrid"
          :key="index"
          class="cell"
          :class="[getCellClass(cell.value), dragDirection]"
      >
        <span v-if="cell.value !== 0">{{ cell.value }}</span>
      </div>
    </div>
    <div class="game-info">
      <p>得分: {{ score }}</p>
      <p>最高分: {{ highScore }}</p>
      <button @click="resetGame">重新开始</button>
      <button @click="toggleMute">{{ isMuted ? '取消静音' : '静音' }}</button>
      <p v-if="gameOver" class="game-over">游戏结束！</p>
    </div>
  </div>
  <!-- 新增：说明区域 -->
  <div class="game-instructions">
    <router-link to="/">
      <button class="back-button">返回主页</button>
    </router-link>
    <h3>游戏说明</h3>
    <ul>
      <li>使用箭头键或鼠标拖动来移动数字瓷砖。</li>
      <li>相同数字的瓷砖会合并，形成更大的数字。</li>
      <li>目标是合并出数字 2048。</li>
      <li>每次移动后会随机生成一个新的数字瓷砖。</li>
      <li>游戏结束条件：没有空位且无法合并相邻瓷砖。</li>
    </ul>
    <h3>操作提示</h3>
    <ul>
      <li>按住箭头键时，瓷砖会根据方向轻微偏移，增强视觉反馈。</li>
      <li>点击“重新开始”按钮可以重置游戏。</li>
      <li>点击“静音”按钮可以切换音效。</li>
    </ul>
  </div>
  </div>
</template>

<script>
import tileMergeSound from '@/assets/sounds/Game2048/tile-merge.mp3';
import tileAddSound from '@/assets/sounds/Game2048/tile-add.mp3';
import gameOverSound from '@/assets/sounds/Game2048/game-over.mp3';
import moveSound from '@/assets/sounds/Game2048/move.mp3';

export default {
  name: 'Game2048',
  data() {
    return {
      // ...其他数据属性
      grid: [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],
      score: 0,
      highScore: parseInt(localStorage.getItem('2048-highScore')) || 0,
      gameOver: false,
      sounds: {
        tileMerge: new Audio(tileMergeSound),
        tileAdd: new Audio(tileAddSound),
        gameOver: new Audio(gameOverSound),
        move: new Audio(moveSound),
      },
      isMuted: false,
      // 鼠标拖拽相关
      mouseStartX: null,
      mouseStartY: null,
      mouseEndX: null,
      mouseEndY: null,
      isDragging: false,
      dragDirection: null, // 拖动方向
    };
  },
  computed: {
    flatGrid() {
      return this.grid.flatMap((row, rowIndex) =>
          row.map((cell, colIndex) => ({
            value: cell,
            row: rowIndex,
            col: colIndex,
          }))
      );
    },
  },
  mounted() {
    this.resetGame();
    this.$refs.board.focus();
    this.$refs.board.addEventListener('keydown', this.handleKey); // 绑定到游戏区域
    this.$refs.board.addEventListener('keyup', this.handleKeyUp); // 绑定到游戏区域
    this.addTouchListeners();
  },
  beforeUnmount() {
    this.$refs.board.removeEventListener('keydown', this.handleKey); // 移除绑定
    this.$refs.board.removeEventListener('keyup', this.handleKeyUp); // 移除绑定
  },
  methods: {
    resetGame() {
      this.grid = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ];
      this.score = 0;
      this.gameOver = false;
      this.addRandomTile();
      this.addRandomTile();
      this.playSound('move'); // 可选：重置游戏时播放音效
    },
    addRandomTile() {
      const emptyCells = [];
      this.grid.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell === 0) {
            emptyCells.push({ row: rowIndex, col: colIndex });
          }
        });
      });

      if (emptyCells.length === 0) return;

      const { row, col } =
          emptyCells[Math.floor(Math.random() * emptyCells.length)];
      const newValue = Math.random() < 0.9 ? 2 : 4;
      this.grid[row][col] = newValue;

      this.playSound('tileAdd');

      this.$nextTick(() => {
        const cellElement = this.$el.querySelector(
            `.game-board .cell:nth-child(${row * 4 + col + 1})`
        );
        if (cellElement) {
          cellElement.classList.add('cell-new');
          setTimeout(() => {
            cellElement.classList.remove('cell-new');
          }, 200);
        }
      });
    },
    handleKey(event) {
      // 检查是否按下的是箭头键
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault(); // 阻止默认的滚动行为
      }

      if (this.gameOver) return;

      // 设置拖动方向
      switch (event.key) {
        case 'ArrowUp':
          this.dragDirection = 'up';
          break;
        case 'ArrowDown':
          this.dragDirection = 'down';
          break;
        case 'ArrowLeft':
          this.dragDirection = 'left';
          break;
        case 'ArrowRight':
          this.dragDirection = 'right';
          break;
        default:
          return;
      }

      let moved = false;
      switch (event.key) {
        case 'ArrowUp':
          moved = this.move('up');
          break;
        case 'ArrowDown':
          moved = this.move('down');
          break;
        case 'ArrowLeft':
          moved = this.move('left');
          break;
        case 'ArrowRight':
          moved = this.move('right');
          break;
        default:
          return;
      }

      if (moved) {
        this.playSound('move');
        this.addRandomTile();
        if (this.isGameOver()) {
          this.gameOver = true;
          this.playSound('gameOver');
          this.updateHighScore();
        }
      }
    },
    handleKeyUp(event) {
      // 重置拖动方向
      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          this.dragDirection = null;
          break;
        default:
          break;
      }
    },
    move(direction) {
      let gridCopy = JSON.parse(JSON.stringify(this.grid));
      const rotation = this.getRotationSteps(direction);
      for (let i = 0; i < rotation; i++) {
        gridCopy = this.rotateGrid(gridCopy);
      }

      let moved = false;

      for (let rowIndex = 0; rowIndex < 4; rowIndex++) {
        let row = gridCopy[rowIndex];
        let compressedRow = this.compress(row);
        let mergedRow = this.merge(compressedRow);
        let finalRow = this.compress(mergedRow);
        if (JSON.stringify(finalRow) !== JSON.stringify(row)) {
          moved = true;
        }
        gridCopy[rowIndex] = finalRow;
      }

      for (let i = 0; i < (4 - rotation) % 4; i++) {
        gridCopy = this.rotateGrid(gridCopy);
      }

      if (moved) {
        this.grid = gridCopy;
        return true;
      }

      return false;
    },
    getRotationSteps(direction) {
      switch (direction) {
        case 'up':
          return 3;
        case 'down':
          return 1;
        case 'left':
          return 0;
        case 'right':
          return 2;
        default:
          return 0;
      }
    },
    rotateGrid(grid) {
      return grid[0].map((_, colIndex) => grid.map(row => row[colIndex]).reverse());
    },
    compress(row) {
      let newRow = row.filter(cell => cell !== 0);
      while (newRow.length < 4) {
        newRow.push(0);
      }
      return newRow;
    },
    merge(row) {
      for (let i = 0; i < 3; i++) {
        if (row[i] !== 0 && row[i] === row[i + 1]) {
          row[i] *= 2;
          this.score += row[i];
          if (this.score > this.highScore) {
            this.highScore = this.score;
            localStorage.setItem('2048-highScore', this.highScore);
          }
          row[i + 1] = 0;
          this.playSound('tileMerge');
          i++;
        }
      }
      return row;
    },
    isGameOver() {
      for (let row of this.grid) {
        if (row.includes(0)) return false;
      }

      for (let row of this.grid) {
        for (let i = 0; i < 3; i++) {
          if (row[i] === row[i + 1]) return false;
        }
      }

      for (let col = 0; col < 4; col++) {
        for (let row = 0; row < 3; row++) {
          if (this.grid[row][col] === this.grid[row + 1][col]) return false;
        }
      }

      return true;
    },
    getCellClass(value) {
      return {
        [`cell-${value}`]: value !== 0,
      };
    },
    updateHighScore() {
      if (this.score > this.highScore) {
        this.highScore = this.score;
        localStorage.setItem('2048-highScore', this.highScore);
      }
    },
    addTouchListeners() {
      let touchStartX = 0;
      let touchStartY = 0;

      this.$refs.board.addEventListener('touchstart', (e) => {
        e.preventDefault(); // 阻止默认行为
        touchStartX = e.changedTouches[0].screenX;
        touchStartY = e.changedTouches[0].screenY;
      });

      this.$refs.board.addEventListener('touchmove', (e) => {
        e.preventDefault(); // 阻止默认行为
      });

      this.$refs.board.addEventListener('touchend', (e) => {
        let touchEndX = e.changedTouches[0].screenX;
        let touchEndY = e.changedTouches[0].screenY;
        this.handleSwipe(touchStartX, touchStartY, touchEndX, touchEndY);
      });
    },
    handleSwipe(startX, startY, endX, endY) {
      const deltaX = endX - startX;
      const deltaY = endY - startY;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 30) {
          if (this.move('right')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        } else if (deltaX < -30) {
          if (this.move('left')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        }
      } else {
        if (deltaY > 30) {
          if (this.move('down')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        } else if (deltaY < -30) {
          if (this.move('up')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        }
      }
    },
    // 新增的鼠标事件处理方法
    handleMouseDown(event) {
      event.preventDefault();
      this.isDragging = true;
      this.mouseStartX = event.clientX;
      this.mouseStartY = event.clientY;
    },
    handleMouseMove(event) {
      if (!this.isDragging) return;
      const deltaX = event.clientX - this.mouseStartX;
      const deltaY = event.clientY - this.mouseStartY;

      // 确定拖动方向
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        this.dragDirection = deltaX > 0 ? 'right' : 'left';
      } else {
        this.dragDirection = deltaY > 0 ? 'down' : 'up';
      }

      // 限制触发频率，避免频繁更新
      // 可选：您可以根据需要添加防抖或节流
    },
    handleMouseUp(event) {
      if (!this.isDragging) return;
      this.isDragging = false;
      this.mouseEndX = event.clientX;
      this.mouseEndY = event.clientY;
      this.handleMouseSwipe();

      // 重置拖动方向
      this.dragDirection = null;
    },
    handleMouseLeave(event) {
      if (!this.isDragging) return;
      this.isDragging = false;
      this.handleMouseSwipe();

      // 重置拖动方向
      this.dragDirection = null;
    },
    handleMouseSwipe() {
      const deltaX = this.mouseEndX - this.mouseStartX;
      const deltaY = this.mouseEndY - this.mouseStartY;
      if (Math.abs(deltaX) < 30 && Math.abs(deltaY) < 30) {
        // 拖拽距离太短，忽略
        return;
      }
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 30) {
          // 向右拖拽
          if (this.move('right')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        } else if (deltaX < -30) {
          // 向左拖拽
          if (this.move('left')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        }
      } else {
        if (deltaY > 30) {
          // 向下拖拽
          if (this.move('down')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        } else if (deltaY < -30) {
          // 向上拖拽
          if (this.move('up')) {
            this.addRandomTile();
            if (this.isGameOver()) {
              this.gameOver = true;
              this.playSound('gameOver');
              this.updateHighScore();
            }
          }
        }
      }
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    playSound(sound) {
      if (this.isMuted) return;
      const audio = this.sounds[sound];
      if (audio) {
        audio.currentTime = 0;
        audio.play();
      }
    },
  },
};
</script>

<style scoped>
/* 全局样式重置 */
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* 防止body出现滚动条 */
}

/* 容器样式 */
.game-container {
  display: flex; /* 使用flex布局 */
  flex-direction: row; /* 默认横向排列 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  padding: 20px;
  min-height: 100vh;
  background-color: #faf8ef;
  font-family: 'Arial', sans-serif;
  overflow: hidden; /* 防止内容溢出 */
}

/* 游戏区域 */
.game-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* 确保不超出容器 */
}

/* 说明区域 */
.game-instructions {
  margin-left: 40px; /* 右侧说明区域与游戏区域的间距 */
  max-width: 300px;
  color: #776e65;
  overflow-y: auto; /* 如果内容过多，允许滚动 */
}

.game-instructions h3 {
  margin-bottom: 10px;
  color: #776e65;
}

.game-instructions ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* 标题样式 */
h2 {
  margin-bottom: 20px;
  color: #776e65;
}

/* 游戏板样式 */
.game-board {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 使用相对单位 */
  grid-template-rows: repeat(4, 1fr);
  gap: 15px;
  background-color: #bbada0;
  padding: 15px;
  border-radius: 10px;
  outline: none;
  user-select: none; /* 防止拖拽时选中文本 */
  cursor: grab; /* 鼠标悬停时显示抓手 */
  width: 90vw; /* 适应不同宽度 */
  max-width: 500px; /* 最大宽度 */
  aspect-ratio: 1 / 1; /* 保持正方形 */
}

.game-board:active {
  cursor: grabbing; /* 拖拽时显示抓手 */
}

/* 瓷砖样式 */
.cell {
  width: 100%;
  height: 100%;
  background-color: #cdc1b4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); /* 动态字体大小 */
  font-weight: bold;
  color: #776e65;
  transition: all 0.2s ease-in-out, transform 0.2s;
}

.cell-new {
  animation: newTile 0.2s ease-in-out;
}

@keyframes newTile {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.cell-2 {
  background-color: #eee4da;
  color: #776e65;
}

.cell-4 {
  background-color: #ede0c8;
  color: #776e65;
}

.cell-8 {
  background-color: #f2b179;
  color: #f9f6f2;
}

.cell-16 {
  background-color: #f59563;
  color: #f9f6f2;
}

.cell-32 {
  background-color: #f67c5f;
  color: #f9f6f2;
}

.cell-64 {
  background-color: #f65e3b;
  color: #f9f6f2;
}

.cell-128 {
  background-color: #edcf72;
  color: #f9f6f2;
}

.cell-256 {
  background-color: #edcc61;
  color: #f9f6f2;
}

.cell-512 {
  background-color: #edc850;
  color: #f9f6f2;
}

.cell-1024 {
  background-color: #edc53f;
  color: #f9f6f2;
}

.cell-2048 {
  background-color: #edc22e;
  color: #f9f6f2;
}

/* 拖动方向对应的偏移 */
.cell.up {
  transform: translateY(-10px);
}

.cell.down {
  transform: translateY(10px);
}

.cell.left {
  transform: translateX(-10px);
}

.cell.right {
  transform: translateX(10px);
}

/* 可选：在拖动时为瓷砖添加阴影效果 */
.cell.up,
.cell.down,
.cell.left,
.cell.right {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 游戏信息区域 */
.game-info {
  margin-top: 20px;
  text-align: center;
}

.game-info p {
  margin: 5px 0;
  font-size: 1.2rem;
  color: #776e65;
}

.game-info button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #8f7a66;
  color: #f9f6f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
}

.game-info button:hover {
  background-color: #9f8b76;
}

.game-over {
  margin-top: 10px;
  color: #ff0000;
  font-weight: bold;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .game-container {
    flex-direction: column; /* 垂直排列 */
    align-items: center;
  }

  .game-instructions {
    margin-left: 0;
    margin-top: 20px;
    max-width: 90%;
  }

  .game-board {
    width: 80vw;
    max-width: 400px;
    gap: 10px;
    padding: 10px;
  }

  .cell {
    font-size: calc(8px + 2vmin); /* 调整字体大小 */
  }
}

.back-button{
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #8f7a66;
  color: #f9f6f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
}
@media (max-width: 480px) {
  .game-board {
    width: 90vw;
    max-width: 350px;
    gap: 8px;
    padding: 8px;
  }

  .game-instructions {
    display: none; /* 在非常小的屏幕上隐藏说明区域 */
  }
}
</style>
