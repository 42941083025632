<template>
  <div class="tetris-container">
    <div class="game-section">
      <canvas
          ref="gameCanvas"
          width="300"
          height="600"
          class="animate__animated"
          :class="{ 'animate__shakeX': gameOver }"
      ></canvas>
      <div class="info">
        <div class="score">分数: {{ score }}</div>
        <div
            v-if="gameOver"
            class="game-over animate__animated animate__flash"
        >
          游戏结束！
        </div>
        <button
            v-if="gameOver"
            @click="startGame"
            class="restart-button animate__animated animate__bounceIn"
        >
          重新开始
        </button>
      </div>
    </div>
    <div class="controls animate__animated animate__fadeIn">
      <button @click="rotate" class="control-button">
        <i class="fas fa-sync-alt"></i>
      </button>
      <div class="horizontal-buttons">
        <button @click="moveLeft" class="control-button">
          <i class="fas fa-arrow-left"></i>
        </button>
        <button @click="moveRight" class="control-button">
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
      <button @click="moveDown" class="control-button">
        <i class="fas fa-arrow-down"></i>
      </button>
      <button @click="drop" class="control-button">
        <i class="fas fa-bomb"></i>
      </button>
      <div class="next-piece">
        <div class="next-piece-title">下一个方块:</div>
        <canvas ref="nextPieceCanvas" width="120" height="120"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tetris",
  data() {
    return {
      canvas: null,
      ctx: null,
      nextPieceCanvas: null,
      nextPieceCtx: null,
      grid: this.createGrid(),
      currentPiece: null,
      nextPiece: null,
      score: 0,
      gameOver: false,
      dropInterval: null,
      dropSpeed: 1000, // 方块下落速度（毫秒）
      colors: {
        I: "cyan",
        J: "blue",
        L: "orange",
        O: "yellow",
        S: "green",
        T: "purple",
        Z: "red",
      },
    };
  },
  methods: {
    // 创建空的游戏网格
    createGrid() {
      const rows = 20;
      const cols = 10;
      const grid = Array.from({ length: rows }, () => Array(cols).fill(0));
      return grid;
    },
    // 开始游戏
    startGame() {
      this.grid = this.createGrid();
      this.score = 0;
      this.gameOver = false;
      this.currentPiece = this.randomPiece();
      this.nextPiece = this.randomPiece();
      this.draw();
      this.drawNextPiece();
      clearInterval(this.dropInterval);
      this.dropInterval = setInterval(this.gameTick, this.dropSpeed);
    },
    // 游戏的每一个时钟滴答
    gameTick() {
      if (!this.moveDown()) {
        this.lockPiece();
        const linesCleared = this.clearLines();
        if (linesCleared > 0) {
          // 添加动画效果
          this.animateLinesCleared(linesCleared);
        }
        this.currentPiece = this.nextPiece;
        this.nextPiece = this.randomPiece();
        this.drawNextPiece();
        if (this.isCollision(this.currentPiece, this.grid)) {
          this.gameOver = true;
          clearInterval(this.dropInterval);
        }
      }
      this.draw();
    },
    // 绘制下一个方块
    drawNextPiece() {
      this.nextPieceCtx.clearRect(0, 0, this.nextPieceCanvas.width, this.nextPieceCanvas.height);
      this.nextPiece.shape.forEach((row, y) => {
        row.forEach((cell, x) => {
          if (cell) {
            this.drawNextPieceCell(x, y, this.colors[this.nextPiece.type]);
          }
        });
      });
    },
    // 绘制下一个方块的单个方块
    drawNextPieceCell(x, y, color) {
      const size = 20;
      const offsetX = (this.nextPieceCanvas.width - this.nextPiece.shape[0].length * size) / 2;
      const offsetY = (this.nextPieceCanvas.height - this.nextPiece.shape.length * size) / 2;
      this.nextPieceCtx.fillStyle = color;
      this.nextPieceCtx.fillRect(x * size + offsetX, y * size + offsetY, size, size);
      this.nextPieceCtx.strokeStyle = "#333";
      this.nextPieceCtx.strokeRect(x * size + offsetX, y * size + offsetY, size, size);
    },
    // 随机生成一个方块
    randomPiece() {
      const pieces = "IJLOSTZ";
      const type = pieces[Math.floor(Math.random() * pieces.length)];
      return {
        type,
        shape: this.getShape(type),
        x: Math.floor((10 - this.getShape(type)[0].length) / 2),
        y: 0,
      };
    },
    // 获取方块形状
    getShape(type) {
      const shapes = {
        I: [
          [0, 0, 0, 0],
          [1, 1, 1, 1],
          [0, 0, 0, 0],
          [0, 0, 0, 0],
        ],
        J: [
          [1, 0, 0],
          [1, 1, 1],
          [0, 0, 0],
        ],
        L: [
          [0, 0, 1],
          [1, 1, 1],
          [0, 0, 0],
        ],
        O: [
          [1, 1],
          [1, 1],
        ],
        S: [
          [0, 1, 1],
          [1, 1, 0],
          [0, 0, 0],
        ],
        T: [
          [0, 1, 0],
          [1, 1, 1],
          [0, 0, 0],
        ],
        Z: [
          [1, 1, 0],
          [0, 1, 1],
          [0, 0, 0],
        ],
      };
      return shapes[type];
    },
    // 绘制游戏画布
    draw() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 绘制固定的方块
      this.grid.forEach((row, y) => {
        row.forEach((cell, x) => {
          if (cell) {
            this.drawCell(x, y, this.colors[cell]);
          }
        });
      });
      // 绘制当前移动的方块
      this.currentPiece.shape.forEach((row, y) => {
        row.forEach((cell, x) => {
          if (cell) {
            this.drawCell(
                this.currentPiece.x + x,
                this.currentPiece.y + y,
                this.colors[this.currentPiece.type]
            );
          }
        });
      });
    },
    // 绘制单个方块
    drawCell(x, y, color) {
      const size = 30;
      this.ctx.fillStyle = color;
      this.ctx.fillRect(x * size, y * size, size, size);
      this.ctx.strokeStyle = "#333";
      this.ctx.strokeRect(x * size, y * size, size, size);
    },
    // 向左移动方块
    moveLeft() {
      if (this.gameOver) return;
      const moved = { ...this.currentPiece, x: this.currentPiece.x - 1 };
      if (!this.isCollision(moved, this.grid)) {
        this.currentPiece = moved;
        this.draw();
      }
    },
    // 向右移动方块
    moveRight() {
      if (this.gameOver) return;
      const moved = { ...this.currentPiece, x: this.currentPiece.x + 1 };
      if (!this.isCollision(moved, this.grid)) {
        this.currentPiece = moved;
        this.draw();
      }
    },
    // 向下移动方块
    moveDown() {
      if (this.gameOver) return;
      const moved = { ...this.currentPiece, y: this.currentPiece.y + 1 };
      if (!this.isCollision(moved, this.grid)) {
        this.currentPiece = moved;
        this.draw();
        return true;
      }
      return false;
    },
    // 旋转方块
    rotate() {
      if (this.gameOver) return;
      const rotated = this.rotateShape(this.currentPiece.shape);
      const rotatedPiece = { ...this.currentPiece, shape: rotated };
      if (!this.isCollision(rotatedPiece, this.grid)) {
        this.currentPiece = rotatedPiece;
        this.draw();
      }
    },
    // 落下方块
    drop() {
      if (this.gameOver) return;
      while (this.moveDown()) {}
      this.gameTick();
    },
    // 旋转形状的辅助函数
    rotateShape(shape) {
      return shape[0].map((_, index) =>
          shape.map((row) => row[index]).reverse()
      );
    },
    // 检测碰撞
    isCollision(piece, grid) {
      return piece.shape.some((row, y) => {
        return row.some((cell, x) => {
          if (cell) {
            const newX = piece.x + x;
            const newY = piece.y + y;
            return (
                newX < 0 ||
                newX >= grid[0].length ||
                newY >= grid.length ||
                (newY >= 0 && grid[newY][newX])
            );
          }
          return false;
        });
      });
    },
    // 将当前方块锁定到网格中
    lockPiece() {
      this.currentPiece.shape.forEach((row, y) => {
        row.forEach((cell, x) => {
          if (cell) {
            const newX = this.currentPiece.x + x;
            const newY = this.currentPiece.y + y;
            if (
                newY >= 0 &&
                newX >= 0 &&
                newX < this.grid[0].length
            ) {
              this.grid[newY][newX] = this.currentPiece.type;
            }
          }
        });
      });
    },
    // 清除已填满的行，并返回清除的行数
    clearLines() {
      let linesCleared = 0;
      this.grid = this.grid.filter((row) => {
        if (row.every((cell) => cell !== 0)) {
          linesCleared++;
          return false;
        }
        return true;
      });
      while (this.grid.length < 20) {
        this.grid.unshift(Array(10).fill(0));
      }
      if (linesCleared > 0) {
        this.score += linesCleared * 100;
      }
      return linesCleared;
    },
    // 添加清除行时的动画效果
    animateLinesCleared(lines) {
      // 这里可以根据清除的行数添加不同的动画效果
      // 例如，闪烁被清除的行或整体游戏区域
      // 目前为示例，暂时不实现具体动画
    },
    // 处理键盘按下事件
    handleKeyDown(event) {
      if (this.gameOver) return;
      switch (event.key) {
        case "ArrowLeft":
          this.moveLeft();
          break;
        case "ArrowRight":
          this.moveRight();
          break;
        case "ArrowDown":
          this.moveDown();
          break;
        case "ArrowUp":
          this.rotate();
          break;
        case " ":
          this.drop();
          break;
        default:
          break;
      }
    },
    // 处理触摸事件（移动端）
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
    },
    handleTouchEnd(event) {
      const touchEndX = event.changedTouches[0].screenX;
      const touchEndY = event.changedTouches[0].screenY;
      const deltaX = touchEndX - this.touchStartX;
      const deltaY = touchEndY - this.touchStartY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 30) {
          this.moveRight();
        } else if (deltaX < -30) {
          this.moveLeft();
        }
      } else {
        if (deltaY > 30) {
          this.moveDown();
        } else if (deltaY < -30) {
          this.rotate();
        }
      }
    },
  },
  mounted() {
    this.canvas = this.$refs.gameCanvas;
    this.ctx = this.canvas.getContext("2d");
    this.nextPieceCanvas = this.$refs.nextPieceCanvas;
    this.nextPieceCtx = this.nextPieceCanvas.getContext("2d");
    document.addEventListener("keydown", this.handleKeyDown);

    // 添加触摸事件监听
    this.canvas.addEventListener("touchstart", this.handleTouchStart, { passive: true });
    this.canvas.addEventListener("touchend", this.handleTouchEnd, { passive: true });

    this.startGame();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
    clearInterval(this.dropInterval);

    // 移除触摸事件监听
    this.canvas.removeEventListener("touchstart", this.handleTouchStart);
    this.canvas.removeEventListener("touchend", this.handleTouchEnd);
  },
};
</script>

<style scoped>
/* 引入 Font Awesome 图标库 */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* 主容器调整为横向布局，并居中对齐 */
.tetris-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  gap: 60px;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  font-family: 'Arial', sans-serif;
}

/* 游戏部分样式 */
.game-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* 画布样式 */
canvas {
  border: 2px solid #61dafb;
  background-color: #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 控制按钮容器 */
.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  background: #f5f5f5;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* 水平按钮容器 */
.horizontal-buttons {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

/* 控制按钮样式 */
.control-button {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 5px 0;
  font-size: 16px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.control-button i {
  margin-right: 8px;
}

.control-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* 下一个方块显示区域 */
.next-piece {
  margin-top: 20px;
  text-align: center;
  color: #333;
}

.next-piece-title {
  font-weight: bold;
  margin-bottom: 10px;
}

/* 信息区域样式 */
.info {
  margin-top: 20px;
  text-align: center;
  color: #333;
}

.score {
  font-size: 24px;
  font-weight: bold;
}

.game-over {
  margin-top: 10px;
  font-size: 28px;
  color: #ff4c4c;
}

.restart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.restart-button:hover {
  background-color: #da190b;
  transform: translateY(-2px);
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .tetris-container {
    flex-direction: column;
    padding: 20px;
    gap: 40px;
  }

  .controls {
    align-items: center;
  }

  .horizontal-buttons {
    width: 120px;
  }

  .control-button,
  .restart-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  canvas {
    width: 240px;
    height: 480px;
  }
}

@media (max-width: 600px) {
  .controls {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .horizontal-buttons {
    width: 100px;
  }

  .control-button,
  .restart-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  canvas {
    width: 180px;
    height: 360px;
  }

  .next-piece-title {
    font-size: 14px;
  }
}

/* 动画效果 */
.animate__animated.animate__shakeX {
  animation-duration: 1s;
}

.animate__animated.animate__flash {
  animation-duration: 1s;
}

.animate__animated.animate__bounceIn {
  animation-duration: 1s;
}

.animate__animated.animate__fadeIn {
  animation-duration: 1s;
}
</style>
