import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Game2048 from '../components/Game2048.vue';
import GameXiaoXiaoLe from '../components/GameXiaoXiaoLe.vue';
import QiaoMuYu from "@/components/QiaoMuYu.vue";
import Tetris from "@/components/Tetris.vue";
import FlappyBird from "@/components/FlappyBird.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/2048',
    name: 'Game2048',
    component: Game2048
  },
  {
    path: '/xiaoxiaole',
    name: 'GameXiaoXiaoLe',
    component: GameXiaoXiaoLe
  },
  {
    path: '/qiaomuyu',
    name: 'QiaoMuYu',
    component: QiaoMuYu
  },
  {
    path: '/tetris',
    name: 'Tetris',
    component: Tetris
  },
  {
    path: '/flappybird',
    name: 'Flappybird',
    component: FlappyBird
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
