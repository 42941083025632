<template>
  <div
      class="flappy-bird-container"
      @mousedown="startFlap"
      @mouseup="stopFlap"
      @touchstart="startFlap"
      @touchend="stopFlap"
  >
    <canvas ref="gameCanvas" width="400" height="600"></canvas>
    <div v-if="gameOver" class="game-over">
      <h2>游戏结束！</h2>
      <p>分数: {{ score }}</p>
      <button @click="restartGame" class="restart-button">重新开始</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlappyBird',
  data() {
    return {
      canvas: null,
      ctx: null,
      bird: {
        x: 50,
        y: 150,
        radius: 12,
        velocity: 0,
        gravity: 0.03, // 降低重力，使小鸟下落更慢
        lift: -3, // 减少上升力量，防止小鸟飞得过高
      },
      pipes: [],
      pipeWidth: 60,
      pipeGap: 200, // 增加管道间隙，使通过更容易
      pipeSpeed: 1, // 减慢管道速度
      frame: 0,
      score: 0,
      gameOver: false,
      flapInterval: null,
    };
  },
  methods: {
    startFlap() {
      this.bird.velocity += this.bird.lift;
    },
    stopFlap() {
      // 当前不需要额外逻辑
    },
    restartGame() {
      this.gameOver = false;
      this.score = 0;
      this.bird.y = 150;
      this.bird.velocity = 0;
      this.pipes = [];
      this.frame = 0;
      this.animate();
    },
    drawBird() {
      this.ctx.beginPath();
      this.ctx.arc(this.bird.x, this.bird.y, this.bird.radius, 0, Math.PI * 2);
      this.ctx.fillStyle = '#FFD700';
      this.ctx.fill();
      this.ctx.closePath();
    },
    drawPipes() {
      this.pipes.forEach(pipe => {
        // 上方管道
        this.ctx.fillStyle = '#228B22';
        this.ctx.fillRect(pipe.x, 0, this.pipeWidth, pipe.top);
        // 下方管道
        this.ctx.fillRect(pipe.x, pipe.bottom, this.pipeWidth, this.canvas.height - pipe.bottom);
      });
    },
    updateBird() {
      this.bird.velocity += this.bird.gravity;
      this.bird.y += this.bird.velocity;

      // 碰到地面或天花板
      if (this.bird.y + this.bird.radius > this.canvas.height || this.bird.y - this.bird.radius < 0) {
        this.endGame();
      }
    },
    updatePipes() {
      this.pipes.forEach(pipe => {
        pipe.x -= this.pipeSpeed;
      });

      // 移除离开屏幕的管道
      this.pipes = this.pipes.filter(pipe => pipe.x + this.pipeWidth > 0);

      // 每 120 帧生成新的管道（增加生成间隔）
      if (this.frame % 120 === 0) {
        const top = Math.floor(Math.random() * (this.canvas.height - this.pipeGap - 100)) + 50;
        const bottom = top + this.pipeGap;
        this.pipes.push({ x: this.canvas.width, top, bottom, passed: false });
      }

      // 检测碰撞
      this.pipes.forEach(pipe => {
        if (
            this.bird.x + this.bird.radius > pipe.x &&
            this.bird.x - this.bird.radius < pipe.x + this.pipeWidth
        ) {
          if (this.bird.y - this.bird.radius < pipe.top || this.bird.y + this.bird.radius > pipe.bottom) {
            this.endGame();
          }
        }

        // 计分
        if (!pipe.passed && pipe.x + this.pipeWidth < this.bird.x) {
          pipe.passed = true;
          this.score += 1;
        }
      });
    },
    drawScore() {
      this.ctx.fillStyle = '#000';
      this.ctx.font = '24px Arial';
      this.ctx.fillText(`分数: ${this.score}`, 10, 30);
    },
    animate() {
      if (this.gameOver) return;

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.updateBird();
      this.updatePipes();

      this.drawBird();
      this.drawPipes();
      this.drawScore();

      this.frame += 1;

      requestAnimationFrame(this.animate);
    },
    endGame() {
      this.gameOver = true;
    },
    setupCanvas() {
      this.canvas = this.$refs.gameCanvas;
      this.ctx = this.canvas.getContext('2d');
    },
  },
  mounted() {
    this.setupCanvas();
    this.animate();
  },
};
</script>

<style scoped>
.flappy-bird-container {
  position: relative;
  width: 400px;
  height: 600px;
  margin: 0 auto;
  background-color: #87CEEB; /* 天空蓝 */
  border: 2px solid #000;
  border-radius: 10px;
  overflow: hidden;
  touch-action: none; /* 防止移动设备上的默认滚动行为 */
}

canvas {
  display: block;
  background-color: #87CEEB; /* 天空蓝 */
}

.game-over {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 40px;
  border: 2px solid #000;
  border-radius: 10px;
  text-align: center;
}

.restart-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.restart-button:hover {
  background-color: #45a049;
}
</style>
