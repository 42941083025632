<!-- src/App.vue -->

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 全局样式（可选） */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
}
</style>
