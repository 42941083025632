<template>
  <div class="container">
    <h1>敲木鱼小游戏</h1>

    <button @click="$router.push('/')" class="back-button">返回首页</button>

    <div class="qiaomu-container" @click="hitQiaoMuYu">


      <img :src="currentImage" alt="木鱼" class="qiaomu-image" />
      <transition-group name="fade" tag="div">
        <span
            v-for="(deed, index) in deeds"
            :key="deed.id"
            class="good-deed-text"
            :style="{ top: deed.y + 'px', left: deed.x + 'px' }"
        >
          功德 +1
        </span>
      </transition-group>
    </div>
    <p class="good-deed-total">功德：{{ goodDeed }}</p>
  </div>
</template>

<script>
export default {
  name: "QiaoMuYu",
  data() {
    return {
      goodDeed: 0,
      qiaoMuYuSound: null,
      currentImageIndex: 0,
      qiaoMuYuImages: [
        require('@/assets/imgs/QiaoMuYu/qiaomu1.png'), // 默认图片
        require('@/assets/imgs/QiaoMuYu/qiaomu2.png'), // 敲击帧1
        require('@/assets/imgs/QiaoMuYu/qiaomu3.png'), // 敲击帧2
        // 可根据需要添加更多帧
      ],
      deeds: [], // 用于存储功德提示
      deedId: 0, // 唯一标识符
    };
  },
  computed: {
    currentImage() {
      return this.qiaoMuYuImages[this.currentImageIndex];
    },
  },
  methods: {
    hitQiaoMuYu(event) {
      this.goodDeed += 1;
      this.playSound();
      this.triggerAnimation();
      this.showGoodDeed(event);
    },
    playSound() {
      if (this.qiaoMuYuSound) {
        this.qiaoMuYuSound.currentTime = 0;
        this.qiaoMuYuSound.play();
      }
    },
    triggerAnimation() {
      this.currentImageIndex = 1; // 切换到敲击帧1
      setTimeout(() => {
        this.currentImageIndex = 2; // 切换到敲击帧2
        setTimeout(() => {
          this.currentImageIndex = 0; // 恢复到默认图片
        }, 100); // 每帧之间的延迟（根据需要调整）
      }, 100);
    },
    showGoodDeed(event) {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left - 50; // 调整位置使文本居中
      const y = event.clientY - rect.top - 30; // 调整位置使文本在木鱼上方

      this.deeds.push({
        id: this.deedId++,
        x: x,
        y: y,
      });

      // 移除动画完成后的文本
      setTimeout(() => {
        this.deeds = this.deeds.filter(deed => deed.id !== this.deedId - 1);
      }, 1000); // 持续时间（根据需要调整）
    },
  },
  mounted() {
    // 加载音效
    this.qiaoMuYuSound = new Audio(require('@/assets/sounds/QiaoMuYu/qiaomu.mp3'));
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.qiaomu-container {
  position: relative;
  cursor: pointer;
  transition: transform 0.1s;
}

.qiaomu-container:active {
  transform: scale(0.95);
}

.qiaomu-image {
  width: 200px;
  max-width: 80vw;
  height: auto;
}

.good-deed-total {
  margin-top: 20px;
  font-size: 1.5em;
  color: #4caf50;
}

/* 功德文本样式 */
.good-deed-text {
  position: absolute;
  font-size: 1em;
  color: #ff5722;
  animation: floatUp 1s forwards;
  pointer-events: none;
}

/* 动画效果 */
@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

/* 过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.back-button {
  padding: 10px 20px; /* 使用固定单位的内边距 */
  font-size: 1em;
  border: none;
  border-radius: 6px;
  background-color: #2196F3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 10px 10px; /* 使用固定单位的外边距 */
}

.back-button:hover {
  background-color: #0b7dda;
  transform: scale(1.05);
}

/* 响应式设计 */
@media (max-width: 600px) {
  .qiaomu-image {
    width: 150px;
  }

  h1 {
    font-size: 1.5em;
  }

  .good-deed-total {
    font-size: 1.2em;
  }

  .good-deed-text {
    font-size: 0.9em;
  }


}
</style>
